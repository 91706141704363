import React from 'react';
import appstore from '../../assets/images/appstore1.png';
import playstore from '../../assets/images/playstore1.png';

const Subscribe = () => {
    return (
        <>
            <div className="newsletter-area pt-110 pb-110 bg-property" data-overlay="black" data-opacity="7" style={{ backgroundImage: `url(https://t3.ftcdn.net/jpg/06/58/31/10/360_F_658311073_jv0oobaa7DOjWlya5LeEx2Wq64iRLyhB.jpg)` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="newsletter-content-wrapper text-center">
                                <h4 className='mb-4' style={{color:"#ffffff"}}>Get News Updates Special Event Notices And <br /> More When You Join Our Email List</h4>
                                <form action="#">
                                    <input type="text" placeholder="Enter Your Email" />
                                    <button className="l-btn pl-60 pr-60 fw-light">Subscribe</button>
                                </form>
                            </div>
                            <div className="newsletter-content-wrapper text-center mt-4">
                                <div action="#">
                                    <h5 style={{ color: "#ffffff" }}>Available on</h5>
                                    <div style={{display:"flex",justifyContent:"center",alignContent:"center"}}>
                                        <img style={{ height: "40px", width: "120px", borderRadius: "8px", cursor: "pointer",margin:"5px" }} src={appstore} alt="" />

                                        <img style={{ height: "40px", width: "130px", borderRadius: "8px", cursor: "pointer",margin:"5px" }} src={playstore} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscribe;