import React from 'react'

const Topbuttons = ({setQuary}) => {

    const cities = [
        {
            id: 1,
            title: "London"
        },
        {
            id: 1,
            title: "Delhi"
        },
        {
            id: 1,
            title: "Sydney"
        },
        {
            id: 1,
            title: "Tokyo"
        },
        {
            id: 1,
            title: "Peris"
        },
    ]

    return (
        <div className='topbuttoncity'>
            {
                cities.map((city, index) => (
                    <button
                    className='cw'
                    onClick={()=> setQuary({q :city.title})}
                    key={index}>
                        {city?.title}
                    </button>
                ))
            }
        </div>
    )
}

export default Topbuttons