import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutVideoBlock from '../components/about/AboutVideoBlock';
import FunFact from '../components/fun-fact/FunFact';
import AboutExperience from '../components/about/AboutExperience';
import AboutBlock from '../components/about/AboutBlock';
import CtaTwo from '../components/cta/CtaTwo';
import AboutTeam from '../components/about/AboutTeam';
import Subscribe from '../components/subscribe/Subscribe';
import TestimonialStyleTwo from '../components/testimonial/TestimonialStyleTwo';
import HowItWorkOne from '../components/how-it-work/HowItWorkOne';
import Mission from './Mission';
import Whyfmc1 from './Whyfmc1';
import OurServices from './OurServices';

const About = () => {
    return (
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={"https://i3.wp.com/images.squarespace-cdn.com/content/v1/6479f1b5ce062e6da6b0ab18/f62c1ada-5328-4560-8cf9-abe415d5fc49/image-asset.jpg?ssl=1"}
                heading="About Our Journey"
                description="Delve into the story behind our platform, our mission, and our dedication to connecting farmers with consumers, fostering sustainable agriculture, and enhancing local communities"
                currentPage="About Us"
            />
            <AboutVideoBlock />
            <Mission />
            <Whyfmc1 />
            <OurServices />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            {/* <AboutTeam /> */}
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <Subscribe/>
            <FooterOne />

        </>
    )
}

export default About;

