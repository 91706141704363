import React from 'react'

const Forecast = ({title,data}) => {

    return (
        <div className='forecast-div'>
            <div className='forecast-div-1'>
                <span className='cw' style={{paddingLeft:"15px"}}>{title}</span>
            </div>

            <hr  style={{marginTop:"5px"}}/>

            <div style={{display:"flex",justifyContent:"space-around"}}>
                {data?.map((data,index) => (
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} key={index}>
                        <span className='cw'>{data.title}</span>
                        <img className='cw' style={{height:"40px",width:"40px"}} src={data.icon} alt="" />
                        <span className='cw' style={{marginTop:"3px"}}>{`${data.temp.toFixed()}°`} </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Forecast