import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import ContactForm from '../components/contact/ContactForm';
import TestimonialStyleThree from '../components/testimonial/TestimonialStyleThree';
import ContactMap from '../components/contact/ContactMap';
import Subscribe from '../components/subscribe/Subscribe';
import FooterTwo from '../common/footer/FooterTwo';
import FooterOne from '../common/footer/FooterOne';

const Contact = () => {
    return (
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={"https://www.adani.com/-/media/Project/Adani/Blog/Growing-apple-red-smiles/blog-banner/Gautam-Adani-IRMA-Speech.jpg"}
                heading="Contact Us"
                description="____________"
                currentPage="Contact Us" 
            />
            <ContactForm />
            {/* <TestimonialStyleThree /> */}
            <ContactMap />
            {/* <Subscribe /> */}
            <FooterOne />
        </>
    )
}

export default Contact;