import React, { useEffect, useRef } from 'react'
import supermarket from '../assets/images/supermarket.jpeg'
import install from '../assets/images/install.png'
import buy from '../assets/images/buyproduct.png'
import cash from '../assets/images/contactBuyer.png'
import delivery from '../assets/images/deal.png'
import image from '../assets/images/FMC.png'
import image1 from '../assets/images/FMC1.png'
import img1 from '../assets/images/supermarketimg1.png'
import playstore from '../assets/images/playstore1.png';
import appstore from '../assets/images/appstore1.png';
import { Grid } from '@mui/material'
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';

const Fmcsupermarket = () => {

    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.navigation.prevEl = prevRef.current;
            swiperRef.current.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    return (
        <div className='market-div pt-50 pb-50'>
            {/* <h1 data-aos="slide-down" className='text-center pb-30'>Glimpse of Farmer Connect</h1> */}
            <h1 className='text-center pb-30'>Glimpse of Farmer Connect</h1>


            <div className='market-div-2'>
                <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <div className='market-div-2_1'>
                            <div className='market-div-2_2'>
                                <div className='market-div-2_3'>
                                    <div className='market-div-2_4'>
                                        <img style={{ height: "45px", width: "45px" }} src={install} alt="" />
                                    </div>
                                </div>
                                <div className='market-div-2_5'>
                                    <h4>Marketplace for Farmers</h4>
                                    <p>Our Farmer Connect Mall is a dedicated space where farmers can showcase and sell their products directly to buyers.</p>
                                </div>
                            </div>
                            <div className='market-div-2_2'>
                                <div className='market-div-2_3'>
                                    <div className='market-div-2_4'>
                                        <img style={{ height: "45px", width: "47px" }} src={buy} alt="" />
                                    </div>
                                </div>
                                <div className='market-div-2_5'>
                                    <h4>Transportation Service</h4>
                                    <p>Efficient and reliable transport is crucial for getting your products to market in the best condition.  </p>
                                </div>
                            </div>
                            <div className='market-div-2_2'>
                                <div className='market-div-2_3'>
                                    <div className='market-div-2_4'>
                                        <img style={{ height: "45px", width: "47px" }} src={cash} alt="" />
                                    </div>
                                </div>
                                <div className='market-div-2_5'>
                                    <h4>Advertising Space</h4>
                                    <p>We provide space for you to advertise your business and reach a wider audience.  </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <div>
                            {/* <img src={image} alt="" style={{width:"250px"}}/> */}
                            <Swiper
                                modules={[Navigation, Autoplay, Pagination]}
                                onSwiper={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    450: { slidesPerView: 1 },
                                    638: { slidesPerView: 1 },
                                    950: { slidesPerView: 1 },
                                    1335: { slidesPerView: 1 },
                                    1586: { slidesPerView: 1 },
                                    1800: { slidesPerView: 1 },
                                    2250: { slidesPerView: 1 },
                                }}
                                loop={true}
                                slidesPerView={1}
                                autoplay={true}
                                // pagination={{ clickable: true }}
                                pagination={{
                                    clickable: true,
                                    el: '.custom-pagination',
                                    bulletClass: 'custom-bullet',
                                    bulletActiveClass: 'custom-bullet-active'
                                  }}
                                spaceBetween={0}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current
                                }}
                                speed={500}
                            >
                                <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={image} alt="" style={{ width: "250px" }} />
                                </SwiperSlide>
                                <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={image1} alt="" style={{ width: "250px" }} />
                                </SwiperSlide>
                            </Swiper>
                            <div className="custom-pagination"></div>
                        </div>
                    </Grid>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>

                        <div className='market-div-2_1'>
                            <div className='market-div-2_2'>
                                <div className='market-div-2_3'>
                                    <div className='market-div-2_4'>
                                        <img style={{ height: "45px", width: "45px" }} src={cash} alt="" />
                                    </div>
                                </div>
                                <div className='market-div-2_5'>
                                    <h4>Labor and Workforce Solutions</h4>
                                    <p>Finding reliable labor can be one of the biggest challenges for farmers.</p>
                                </div>
                            </div>
                            <div className='market-div-2_2'>
                                <div className='market-div-2_3'>
                                    <div className='market-div-2_4'>
                                        <img style={{ height: "45px", width: "45px" }} src={delivery} alt="" />
                                    </div>
                                </div>
                                <div className='market-div-2_5'>
                                    <h4>Community Marketplace</h4>
                                    <p>Our platform allows anyone to post their requirements and items for sale.</p>
                                </div>
                            </div>
                            <div className='market-div-2_2'>
                                <div className='market-div-2_3'>
                                    <div className='market-div-2_4'>
                                        <img style={{ height: "45px", width: "45px" }} src={install} alt="" />
                                    </div>
                                </div>
                                <div className='market-div-2_5'>
                                    <h4>Install Appplication</h4>
                                    <p>Instant notifications for new product listings, job postings, and messages.Real-time updates on market prices.</p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Fmcsupermarket