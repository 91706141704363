import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import BlogStyleFourImg1 from '../../assets/images/blog/bl-1.jpg';
import BlogStyleFourImg2 from '../../assets/images/blog/bl-2.jpg';
import BlogStyleFourImg3 from '../../assets/images/blog/bl-3.jpg';
import Pagination from '../pagination/Pagination';
import SearchWidget from '../widgets/SearchWidget';
import CategoryWidget from '../widgets/CategoryWidget';
import LatestPostWidget from '../widgets/LatestPostWidget';
import TagsWidget from '../widgets/TagsWidget';
import ContactWidget from '../widgets/ContactWidget';
import QuoteWidget from '../widgets/QuoteWidget';

const BlogStyleFourData = [

    {
        id: uuidv4(),
        img: "https://cdn.hswstatic.com/gif/Compost-for-vegetables.jpg",
        parentCat: ' Farming',
        regularCat: 'Organic Gardening',
        heading: ' How to Start Your Own Organic Vegetable Garden.',
        date: '25 June 2024',
        author: ' Admin',
        description: 'Discover the essential steps to kickstart your own organic vegetable garden. Learn about soil preparation, seed selection, and sustainable gardening practices.',
        btnLink: '/blog-details',
        btnText: 'Read More',
    },

    {
        id: uuidv4(),
        img: "https://media.istockphoto.com/id/1208790364/photo/healthy-fresh-rainbow-colored-fruits-and-vegetables-in-a-row.jpg?s=612x612&w=0&k=20&c=PiHXSePkFnNJ5PKvJcstycqx-V9mujWTDhBsYqKDtCA=",
        parentCat: 'Vegetables & Fruits',
        regularCat: 'Healthy Eating',
        heading: 'The Benefits of Eating Seasonal Fruits and Vegetables',
        date: '27 June 2024',
        author: ' Admin',
        description: 'Explore the health and environmental benefits of consuming seasonal produce. Understand why eating in season is better for you and the planet',
        btnLink: '/blog-details',
        btnText: 'Read More',
    },

    {
        id: uuidv4(),
        img: "https://miro.medium.com/v2/resize:fit:1400/1*_PzPsVZZq2d9MAalF8FVgQ.jpeg",
        parentCat: 'Farming',
        regularCat: 'Sustainable Agriculture',
        heading: 'Sustainable Farming Practices for Modern Agriculture',
        date: '30 June 2024',
        author: ' Admin',
        description: 'Learn about innovative and sustainable farming practices that can help modernize agriculture. Focus on techniques that enhance productivity while protecting the environment',
        btnLink: '/blog-details',
        btnText: 'Read More',
    },

    {
        id: uuidv4(),
        img: "https://www.zettafarms.com/wp-content/uploads/2024/01/2952.jpg",
        parentCat: 'Machinery',
        regularCat: 'Agricultural Technology',
        heading: 'How Technology is Revolutionizing Farm Equipment',
        date: '05 July 2024',
        author: ' Admin',
        description: ' Delve into the latest technological advancements in farm machinery. See how new tools and equipment are making farming more efficient and sustainable.',
        btnLink: '/blog-details',
        btnText: 'Read More',
    }


];

const BlogStyleFourItem = ({ img, parentCat, regularCat, heading, date, author, description, btnLink, btnText }) => {
    return (
        <>
            <div className="col-xl-12">
                <div className="single-blog-wrapper mb-60 border-bottom-1">
                    <div  data-aos="fade-right" className="blog-img">
                        <img src={img} className="img-fluid" alt="" />
                        <Link to="/"><FaPlus /></Link>
                    </div>
                    <div data-aos="fade-right" className="blog-content mb-60">
                        <ul className="cat-list mt-30 mb-20">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul>
                        <h2><Link to="/">{heading}</Link></h2>
                        <ul className="post-meta mb-25">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span><Link to="/">{author}</Link></li>
                        </ul>
                        <p className="mb-35">{description}</p>
                        <Link to={btnLink} className="l-btn">{btnText}</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

const BlogStyleFour = () => {
    return (
        <>
            <div className="blog-list pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 pr-50">
                            <div className="row">
                                <div className="row">
                                    {BlogStyleFourData.map((data) => (
                                        <BlogStyleFourItem
                                            key={data.id}
                                            img={data.img}
                                            parentCat={data.parentCat}
                                            regularCat={data.regularCat}
                                            heading={data.heading}
                                            date={data.date}
                                            author={data.author}
                                            description={data.description}
                                            btnLink={data.btnLink}
                                            btnText={data.btnText}
                                        />
                                    ))}
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-12">
                                    <Pagination />
                                </div>
                            </div> */}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 pr-50">
                            <SearchWidget />
                            <CategoryWidget />
                            <LatestPostWidget />
                            <TagsWidget />
                            <ContactWidget />
                            <QuoteWidget />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogStyleFour;