import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import ProjectStyleFour from '../components/project/ProjectStyleFour';
import CtaTwo from '../components/cta/CtaTwo';
import WhyChoseUsTwo from '../components/why-choose-us/WhyChoseUsTwo';
import Subscribe from '../components/subscribe/Subscribe';
import FooterOne from '../common/footer/FooterOne';

const Gallery = () => {
    return (
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={"https://t4.ftcdn.net/jpg/01/26/35/35/360_F_126353528_Lxr7a33xiRVpuYIuEv40GyW8MQ4N2b9E.jpg"}
                heading="Classic Gallery"
                description="We are available for 24/7 for Garden & Landscaping Services"
                currentPage="Classic Gallery" 
            />
            <ProjectStyleFour />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            <Subscribe />
            <FooterOne />
           
        </>
    )
}

export default Gallery;