import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import HeroStyleThreeImg1 from '../../assets/images/hero/3.jpg';
import HeroStyleThreeImg2 from '../../assets/images/hero/2.jpg';
import HeroStyleThreeImg3 from '../../assets/images/hero/1.jpg';

const HeroStyleThreeSliderData = [
    
    {
        id: uuidv4(),
        img: "https://www.theindiaforum.in/sites/default/files/field/image/2022/06/21/ramkumar-radhakrishnan-wikimedia-1622193304-1622193304.jpeg",
        heading: 'Welcome to Farmer Connect',
        description: ' Where Farmers Meet Customers for Fresh, Sustainable Food. Explore our platform to discover a diverse range of locally sourced produce, connect directly with farmers, and support sustainable agriculture practices.',
        btnLink: '/',
        btnText: 'Our Solution',
    },
    {
        id: uuidv4(),
        img: "https://c1.wallpaperflare.com/preview/884/928/958/home-outside-thailand-cornfield-rice.jpg",
        heading: 'Welcome to Farmer Connect',
        description: ' Where Farmers Meet Customers for Fresh, Sustainable Food. Explore our platform to discover a diverse range of locally sourced produce, connect directly with farmers, and support sustainable agriculture practices.',
        btnLink: '/',
        btnText: 'Our Solution',
    },
    
    {
        id: uuidv4(),
        img: "https://w0.peakpx.com/wallpaper/192/253/HD-wallpaper-farming-agriculture-field-farm-farmer-indian.jpg",
        heading: 'Welcome to Farmer Connect',
        description: ' Where Farmers Meet Customers for Fresh, Sustainable Food. Explore our platform to discover a diverse range of locally sourced produce, connect directly with farmers, and support sustainable agriculture practices.',
        btnLink: '/',
        btnText: 'Our Solution',
    },
    
    {
        id: uuidv4(),
        img: "https://img.freepik.com/premium-photo/indian-farmer-holding-cotton-plant-hand-inspecting-plant_75648-1318.jpg?w=996",
        heading: 'Welcome to Farmer Connect',
        description: ' Where Farmers Meet Customers for Fresh, Sustainable Food. Explore our platform to discover a diverse range of locally sourced produce, connect directly with farmers, and support sustainable agriculture practices.',
        btnLink: '/',
        btnText: 'Our Solution',
    },

];

const HeroStyleThreeSliderItem = ({ img, heading, description, btnLink, btnText }) => {
    return (
        <>
            <div  className="single-slider-style-2 height-680 bg-property d-flex align-items-center" data-overlay="black" data-opacity="4" style={{ backgroundImage: `url(${img})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="header-btn slide-content-wrapper-2">
                                <h1>{heading}</h1>
                                <p>{description}</p>
                                <a href={btnLink} className="l-btn">{btnText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const HeroStyleThreeSliderBoxData = [
    
    {
        id: uuidv4(),
        heading: 'Step 1',
        description: 'There are many variations of passages of Lorem Ipsum available.',
    },
    
    {
        id: uuidv4(),
        heading: 'Step 2',
        description: 'There are many variations of passages of Lorem Ipsum available.',
    },
    
    {
        id: uuidv4(),
        heading: 'Step 3',
        description: 'There are many variations of passages of Lorem Ipsum available.',
    },
    
    {
        id: uuidv4(),
        heading: 'Step 4',
        description: 'There are many variations of passages of Lorem Ipsum available.',
    },

];

const HeroStyleThreeSliderBoxItem = ({ heading, description }) => {
    return (
        <>
            <div className="single-slider-box pt-40 pb-20 pl-20 pr-20">
                <h3 className="white-color">{heading}</h3>
                <p className="white-color">{description}</p>
            </div>
        </>
    )
}

const HeroStyleThree = () => {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <>
            <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                slidesToShow={1} 
                slidesToScroll={1} 
                arrows={false} 
                fade={true} 
                className="hero-slider-2">
                    {HeroStyleThreeSliderData.map((data) => (
                        <HeroStyleThreeSliderItem
                            key={data.id}
                            img={data.img}
                            heading={data.heading}
                            description={data.description}
                            btnLink={data.btnLink}
                            btnText={data.btnText}
                        />
                    ))}
            </Slider>



            {/* <div className="hero-slider-box theme-bg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <Slider asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={3}
                                slidesToScroll={1}
                                dots={false} 
                                arrows={true} 
                                centerMode={false} 
                                infinite={true} 
                                prevArrow={<button type="button" className="slick-prev"><FaChevronLeft /></button>} 
                                nextArrow={<button type="button" className="slick-next"><FaChevronRight /></button>} 
                                responsive={ [{
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 2,
                                        }
                                    },
                                    {
                                        breakpoint: 600,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    },
                                    {
                                        breakpoint: 480,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    }
                                ]}
                                className="slider-box pr-200">
                                    {HeroStyleThreeSliderBoxData.map((data) => (
                                        <HeroStyleThreeSliderBoxItem
                                            key={data.id}
                                            heading={data.heading}
                                            description={data.description}
                                        />
                                    ))}
                            </Slider>
                            
                        </div>
                    </div>
                </div>
            </div> */}
                        
        </>
    )
}

export default HeroStyleThree