import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Weather from "../../components/weather"



function MyVerticallyCenteredModal(props) {
    return (
        <div className='weathermodel'>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header > */}
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title> */}


                {/* </Modal.Header > */}
                <Modal.Body style={{ padding: "0px" }} >
                    {/* <h4>Centered Modal</h4>
                <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                </p> */}
                    <Weather />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}


const HeaderBottomOne = () => {

    const [weatherpopup, setWeatherPopUp] = useState(false)


    return (
        <>
            <div  className="header-bottom">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-4">
                            <Logo />
                        </div>
                        <div className="col-xl-10 col-lg-10 col-md-8">
                            <div className="header-btn f-right">
                                <Button onClick={() => setWeatherPopUp(true)} to="#" className="l-btn">Weather</Button>
                            </div>
                            <Nav />
                        </div>
                        <div className="col-12">
                            <div className="mobile-menu">
                                <MobileMenu />
                            </div>      
                        </div>
                    </div>
                </div>

                <MyVerticallyCenteredModal
                    show={weatherpopup}
                    onHide={() => setWeatherPopUp(false)}
                />
            </div>
        </>
    )
}

export default HeaderBottomOne;