import React from 'react';
import { Link } from 'react-router-dom';
import { FaQuoteLeft, FaFacebookF, FaTwitter, FaPinterestP } from 'react-icons/fa';
import BlogContentImg1 from '../../assets/images/blog/bl-1.jpg';
import BlogContentImg2 from '../../assets/images/blog/bl-4.jpg';

const BlogContent = () => {
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="blog-details pb-60 mb-60 border-bottom-1">
                    <img className="img-fluid mb-30" src={'https://media.licdn.com/dms/image/C5612AQHY6WosFtzXOg/article-cover_image-shrink_720_1280/0/1588342419628?e=2147483647&v=beta&t=Qkk_8nzvnW80uym0YvF2pdJiol8qPCQ7nje45N0MEdI'} alt="" />
                    <p>
                        Agriculture has always been the backbone of human civilization, providing food, raw materials, and livelihoods to billions of people worldwide. As we move further into the 21st century, the farming industry faces numerous challenges, including climate change, population growth, and the need for sustainable practices. However, with the advent of modern technology, the future of farming looks promising. Let's explore some of the ways technology is revolutionizing agriculture and paving the way for a sustainable future.


                    </p>
                    <h2>Our Best Garden & Landscape Services</h2>
                    <p>
                        The future of farming lies in the successful integration of technology and sustainable practices. By embracing innovations such as precision agriculture, drones, AI, vertical farming, blockchain, and climate-resilient crops, farmers can overcome challenges and create a more efficient, resilient, and environmentally friendly agricultural system. As we continue to explore and develop these technologies, the farming industry will play a crucial role in feeding the world's growing population while preserving the planet for future generations.

                    </p>
                    <div className="quote-block text-center">
                        <div className="quote-icon">
                            <FaQuoteLeft />
                        </div>
                        <p>Precision agriculture is a farming management concept that uses technology to monitor and optimize agricultural processes. 
                        </p>
                    </div>
                    <p>
                    As urbanization continues to expand, traditional farmland is becoming scarce. Vertical farming and hydroponics offer innovative solutions to this problem by allowing crops to be grown in controlled environments, such as skyscrapers or shipping containers. These systems use artificial lighting, nutrient-rich water, and climate control to create optimal growing conditions. Vertical farming and hydroponics maximize space utilization, reduce water usage, and eliminate the need for pesticides, making them ideal for sustainable urban agriculture.

                    </p>
                    <img className="img-fluid mt-20 mb-30" src={'https://media.licdn.com/dms/image/D4E12AQGD_jiYI3nP1g/article-cover_image-shrink_720_1280/0/1678157174007?e=2147483647&v=beta&t=uXpj_uVXve97XZUD9bRLt0F23ldcWrdJsDz5j446KOs'} alt="" />
                    <p>
                        
                    Climate change poses a significant threat to global food security, with unpredictable weather patterns and extreme conditions affecting crop yields. Researchers are developing climate-resilient crops through genetic modification and traditional breeding techniques. These crops are engineered to withstand drought, heat, and disease, ensuring stable food production in the face of climate challenges. By investing in climate-resilient crops, farmers can safeguard their livelihoods and contribute to a more sustainable food system.
                    </p>
                    <div className="row mt-50">
                        <div className="col-xl-10 col-lg-8 col-md-7 col-sm-12">
                            <div className="post-tags">
                                <h5>Post Tags:</h5>
                                <ul>
                                    <li><Link to="/">Gardening</Link></li>
                                    <li><Link to="/">Tree</Link></li>
                                    <li><Link to="/">Landscape</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-xl-2 col-lg-4 col-md-5 col-sm-12 text-end">
                            <div className="post-share">
                                <h5>Share:</h5>
                                <ul>
                                    <li><Link to="/"><FaFacebookF /></Link></li>
                                    <li><Link to="/"><FaTwitter /></Link></li>
                                    <li><Link to="/"><FaPinterestP /></Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogContent;