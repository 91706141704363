import React from 'react';
import {Link} from 'react-router-dom';

const CategoryWidget = () => {
    return (
        <>
            <div data-aos="fade-left" className="widget category-widget mb-60">
            <h3 className="widget-title">Categories</h3>
                <ul>
                    <li><Link to="/">Farming</Link><span>(20)</span></li>
                    <li><Link to="/">Crops</Link><span>(13)</span></li>
                    <li><Link to="/">Seeds</Link><span>(13)</span></li>
                    <li><Link to="/">Vegetables & Fruits</Link><span>(10)</span></li>
                    <li><Link to="/">Labour</Link><span>(15)</span></li>
                    <li><Link to="/"> Transport</Link><span>(18)</span></li>
                    <li><Link to="/"> Machinary</Link><span>(18)</span></li>
                    <li><Link to="/"> Buyers</Link><span>(18)</span></li>
                    <li><Link to="/"> Storage</Link><span>(18)</span></li>
                    <li><Link to="/"> Animals</Link><span>(18)</span></li>
                    <li><Link to="/"> FMFarmer Connect Supermarket</Link><span>(18)</span></li>
                </ul>
            </div>
        </>
    )
}

export default CategoryWidget;