import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FaLink, FaSearchPlus } from 'react-icons/fa';
import Lightbox from "yet-another-react-lightbox";
import ProjectStyleFourImg1 from '../../assets/images/project/landscape-design.jpg';
import ProjectStyleFourImg2 from '../../assets/images/project/tree-plantation.jpg';
import ProjectStyleFourImg3 from '../../assets/images/project/watering.jpg';
import ProjectStyleFourImg4 from '../../assets/images/project/plant-growing.jpg';
import ProjectStyleFourImg5 from '../../assets/images/project/garden-care.jpg';
import ProjectStyleFourImg6 from '../../assets/images/project/flower-scaping.jpg';
import ProjectStyleFourImg7 from '../../assets/images/project/commercial-gardening.jpg';
import ProjectStyleFourImg8 from '../../assets/images/project/outdoor-scaping.jpg';
import ProjectStyleFourImg9 from '../../assets/images/project/branch-cutting.jpg';
import ProjectStyleFourImg10 from '../../assets/images/project/p1.jpg';
import ProjectStyleFourImg11 from '../../assets/images/project/p2.jpg';
import ProjectStyleFourImg12 from '../../assets/images/project/p3.jpg';

const ProjectStyleFourData = [

    {
        id: uuidv4(),
        img: "https://media.istockphoto.com/id/668076810/photo/man-sowing-the-land-at-a-farm.jpg?s=612x612&w=0&k=20&c=ER76kCd7gnsiAAGFZny3Tp7kyT59dNc3HrSi0WvbP0M=",
        heading: 'Landscape Design',
        btnLink: '/project-details',
        category: 'planting',
        imgPopup: "https://media.istockphoto.com/id/668076810/photo/man-sowing-the-land-at-a-farm.jpg?s=612x612&w=0&k=20&c=ER76kCd7gnsiAAGFZny3Tp7kyT59dNc3HrSi0WvbP0M=",
    },

    {
        id: uuidv4(),
        img: "https://media.istockphoto.com/id/516187226/photo/perfectly-striped-freshly-mowed-garden-lawn.jpg?s=612x612&w=0&k=20&c=qynDqjh89OZCtoAosX_dfHdFhx569RV1lhUdfBXblE0=",
        heading: 'Tree Plantation',
        btnLink: '/project-details',
        category: 'lawn',
        imgPopup: "https://media.istockphoto.com/id/516187226/photo/perfectly-striped-freshly-mowed-garden-lawn.jpg?s=612x612&w=0&k=20&c=qynDqjh89OZCtoAosX_dfHdFhx569RV1lhUdfBXblE0=",
    },

    {
        id: uuidv4(),
        img: "https://www.shutterstock.com/shutterstock/videos/1057764157/thumb/1.jpg?ip=x480",
        heading: 'Watering',
        btnLink: '/project-details',
        category: 'video',
        imgPopup: "https://www.shutterstock.com/shutterstock/videos/1057764157/thumb/1.jpg?ip=x480",
    },

    {
        id: uuidv4(),
        img: "https://i.pinimg.com/originals/89/82/a1/8982a1e2d90a81d5a447ad83936b7209.jpg",
        heading: 'Plant Growing',
        btnLink: '/project-details',
        category: 'planting',
        imgPopup: "https://i.pinimg.com/originals/89/82/a1/8982a1e2d90a81d5a447ad83936b7209.jpg",
    },

    {
        id: uuidv4(),
        img: "https://as1.ftcdn.net/v2/jpg/06/86/32/94/1000_F_686329401_7l4nWdV7uBBzZio1bKZLNNctOU5DXjlV.jpg",
        heading: 'Garden Care',
        btnLink: '/project-details',
        category: 'lawn',
        imgPopup: "https://as1.ftcdn.net/v2/jpg/06/86/32/94/1000_F_686329401_7l4nWdV7uBBzZio1bKZLNNctOU5DXjlV.jpg",
    },

    {
        id: uuidv4(),
        img: "https://c8.alamy.com/comp/AP1GG1/agriculture-snow-covered-field-of-corn-stubble-in-winter-with-a-farmstead-AP1GG1.jpg",
        heading: 'Flower Scaping',
        btnLink: '/project-details',
        category: 'snow',
        imgPopup: "https://c8.alamy.com/comp/AP1GG1/agriculture-snow-covered-field-of-corn-stubble-in-winter-with-a-farmstead-AP1GG1.jpg",
    },

    {
        id: uuidv4(),
        img: "https://static.vecteezy.com/system/resources/thumbnails/005/886/593/small_2x/young-smart-farmer-controlling-drone-spraying-fertilizer-and-pesticide-over-farmland-high-technology-innovations-and-smart-farming-free-video.jpg",
        heading: 'Commercial Gardening',
        btnLink: '/project-details',
        category: 'video',
        imgPopup: "https://static.vecteezy.com/system/resources/thumbnails/005/886/593/small_2x/young-smart-farmer-controlling-drone-spraying-fertilizer-and-pesticide-over-farmland-high-technology-innovations-and-smart-farming-free-video.jpg",
    },

    {
        id: uuidv4(),
        img: "https://images.unsplash.com/photo-1625246333195-78d9c38ad449?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b3JnYW5pYyUyMGZhcm1pbmd8ZW58MHx8MHx8fDA%3D",
        heading: 'Outdoor Scaping',
        btnLink: '/project-details',
        category: 'garden',
        imgPopup: "https://images.unsplash.com/photo-1625246333195-78d9c38ad449?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b3JnYW5pYyUyMGZhcm1pbmd8ZW58MHx8MHx8fDA%3D",
    },

    {
        id: uuidv4(),
        img: "https://cdn.shopify.com/s/files/1/0614/3724/7737/articles/backyard-farming-ideas-for-aspiring-urban-grower-1900.jpg?v=1681932376",
        heading: 'Branch Cutting',
        btnLink: '/project-details',
        category: 'garden',
        imgPopup: "https://cdn.shopify.com/s/files/1/0614/3724/7737/articles/backyard-farming-ideas-for-aspiring-urban-grower-1900.jpg?v=1681932376",
    },

    {
        id: uuidv4(),
        img: "https://www.ugaoo.com/cdn/shop/articles/shutterstock_651730000.jpg?v=1661883506",
        heading: 'Scaping',
        btnLink: '/project-details',
        category: 'garden',
        imgPopup: "https://www.ugaoo.com/cdn/shop/articles/shutterstock_651730000.jpg?v=1661883506",
    },

    {
        id: uuidv4(),
        img: "https://images.squarespace-cdn.com/content/v1/59cb502e1f318dc50f160957/94a8f929-5013-45d1-bc21-ca8ef3dda873/beautiful-gardens-in-the-world.jpg",
        heading: 'Cutting',
        btnLink: '/project-details',
        category: 'garden',
        imgPopup: "https://images.squarespace-cdn.com/content/v1/59cb502e1f318dc50f160957/94a8f929-5013-45d1-bc21-ca8ef3dda873/beautiful-gardens-in-the-world.jpg",
    },

    {
        id: uuidv4(),
        img: "https://okcredit-blog-images-prod.storage.googleapis.com/2021/07/Gardening1--1-.jpg",
        heading: 'Commercial Gardening',
        btnLink: '/project-details',
        category: 'garden',
        imgPopup: "https://okcredit-blog-images-prod.storage.googleapis.com/2021/07/Gardening1--1-.jpg",
    },

];

const ProjectStyleFourItem = ({ img, imgPopup, heading, btnLink }) => {




    const [open, setOpen] = React.useState(false);
    return (
        <>

            <div className="col-xl-3 col-lg-3 col-md-4 planting">
                <div className="single-project-isotope-style-2 bg-property mb-25" style={{ backgroundImage: `url(${img})` }}>
                    <div className="portfolio-content-2">
                        <button type="button" onClick={() => setOpen(true)} className="popup-image">
                            <FaSearchPlus />
                        </button>
                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                                { src: imgPopup },
                            ]}
                        />
                        {/* <Link to={btnLink}><FaLink /></Link> */}
                    </div>
                    <div className="portfolio-heading-content-2">
                        <h3>{heading}</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

const CategoryBtn = ({ name, handleSetCategory, label, className }) => {
    return (
        <>
            <button className={className} type="button" onClick={() => handleSetCategory(name)}>
                {label}
            </button>
        </>
    )
}

const ProjectStyleFour = () => {
    // STATES
    const [category, setCategory] = useState('all');
    const [projectStyleFourItem, setProjectStyleFourItem] = useState([]);

    useEffect(() => {
        category === 'all'
            ? setProjectStyleFourItem(ProjectStyleFourData)
            : setProjectStyleFourItem(ProjectStyleFourData.filter((data) => data.category === category));
    }, [category]);

    return (
        <>
            <div data-aos="zoom-out-up" className="project-area pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div data-aos="slide-up" className="section-title text-center mb-70">
                                {/* <span>Our Projects</span> */}
                                {/* <h2>Our Successful Projects</h2> */}
                                <h2>Our  Projects</h2>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-out-up" className="row">
                        <div className="col-lg-12 text-center">
                            <div className="portfolio-menu mb-60">
                                <CategoryBtn
                                    className={category === 'all' ? 'active' : null}
                                    name="all"
                                    label="All"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'lawn' ? 'active' : null}
                                    name="lawn"
                                    label="Lawn Care"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'video' ? 'active' : null}
                                    name="video"
                                    label="Video Gallery"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'garden' ? 'active' : null}
                                    name="garden"
                                    label="Garden Care"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'planting' ? 'active' : null}
                                    name="planting"
                                    label="Planting"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'snow' ? 'active' : null}
                                    name="snow"
                                    label="Snow Removal"
                                    handleSetCategory={setCategory}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row portfolio-active">
                        {projectStyleFourItem.map(
                            (item, length) =>
                                length <= '12' && (
                                    <ProjectStyleFourItem
                                        key={item.id}
                                        img={item.img}
                                        imgPopup={item.imgPopup}
                                        heading={item.heading}
                                        category={item.category}
                                        btnLink={item.btnLink}
                                    />
                                )
                        )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectStyleFour;

