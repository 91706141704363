import React from 'react'

const Timeandlocation = ({ weather: { formattedLocalTime, name, country } }) => {
    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "13px" }}>
                <span className='cw' style={{ fontSize: "0.88rem" }}>
                    {/* Tuesday 14 May ,2024 | Local Time : 07:25 AM */}
                    {formattedLocalTime}
                </span>
            </div>
            <div style={{ marginTop: "15px", textAlign: "center" }}>
                <span className='cw' style={{ fontSize: "1.25rem", fontWeight: "600" }}>
                    {/* Berlin , DE */}
                    {`${name},${country}`}
                </span>
            </div>
        </div>
    )
}

export default Timeandlocation