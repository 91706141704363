import React, { useEffect, useState } from 'react'
import UilReact from '@iconscout/react-unicons/icons/uil-react'
import Inputs from './Inputs'
import getformattedWeatherData from './Weatherservice'
import Timeandlocation from './Timeandlocation'
import TempandDetail from './TempandDetail'
import Forecast from './Forecast'
import getWeatherData from './Weatherservice'
import Topbuttons from './Topbuttons'

const Whether = () => {

    const [quary, setQuary] = useState({ q: "delhi" })
    const [units, setUnits] = useState('metric')
    const [weather, setWeather] = useState(null)

    const fetchWeatherData = async () => {
        const data = await getformattedWeatherData({ ...quary, units })
            .then((data) => {
                setWeather(data)
            })
        console.log("data is is is", weather)
    }

    useEffect(() => {
        fetchWeatherData()
    }, [quary , units])


    return (
        <div>
            <div className='topbutton-div'>
                <Topbuttons setQuary={setQuary} />
                <Inputs setQuary={setQuary} />
                {
                    weather && (
                        <>
                            <Timeandlocation weather={weather} />
                            <TempandDetail weather={weather} />
                            <Forecast title="3 HOUR STEP FORECAST" data={weather.hourly} />
                            <Forecast title="DAILY FORECAST DATA" data={weather.daily} />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default Whether