import React from 'react'
import image1 from '../assets/images/Services/market.png'
import image2 from '../assets/images/Services/workforce.png'
import image3 from '../assets/images/Services/truck.png'
import image4 from '../assets/images/Services/monopoly.png'
import image5 from '../assets/images/Services/social-media.png'


const OurServices = () => {


    const services = [
        {
            id: 1,
            title: "Marketplace for Farmers",
            desc: "Our Farmer Connect Mall is a dedicated space where farmers can showcase and sell their products directly to buyers. By contacting us, you can gain access to a broader market, ensuring that your hard work gets the recognition and reward it deserves.",
            image: image1
        },
        {
            id: 2,
            title: "Labor and Workforce Solutions",
            desc: "Finding reliable labor can be one of the biggest challenges for farmers. At Farmer Connect, we provide a platform to connect you with workers who are ready and willing to help with your farming needs.",
            image: image2
        },
        {
            id: 3,
            title: "Transportation Service",
            desc: "Efficient and reliable transport is crucial for getting your products to market in the best condition. We offer solutions to help you find the right transportation, ensuring that your goods arrive fresh and on time.",
            image: image3
        },
        {
            id: 4,
            title: "Community Marketplace",
            desc: "Our platform allows anyone to post their requirements and items for sale. Whether you're a farmer looking to buy or sell produce, lease tools, or find transport and labor, you can easily communicate and share contacts with other users. This feature enables buyers and sellers to negotiate and agree on prices directly, fostering a collaborative and supportive farming community.",
            image: image4
        },
        {
            id: 5,
            title: "Advertising Space:",
            desc: "We provide space for you to advertise your business and reach a wider audience. By promoting your services and products on Farmer Connect, you can increase your visibility and connect with potential customers and partners more effectively.",
            image: image5
        },
    ]


    return (
        <div className='our-services-div mt-5'>
            <div className='our-services-div-2' style={{ backgroundSize: "cover", backgroundImage: "url(https://housing.com/news/wp-content/uploads/2023/03/Trending-design-ideas-for-wall-shelves-to-make-storage-stylish-f.jpg)" }}>
                <div className='our-services-div-2_1' >
                    <h1 data-aos="slide-down" className=' text-center'>Our Services</h1>
                    <p data-aos="slide-up" className=' '>Our goal is to empower farmers by simplifying these essential aspects of farming life. At Farmer Connect, we believe that by supporting farmers, we are not just helping individuals, but also fostering stronger, more resilient communities.</p>
                </div>
            </div>


            <div className='our-services-div-1'>


                {
                    services?.map((data, index) => (
                        <div data-aos={index % 2 === 0 ? 'slide-right' : 'slide-left'} className="our-services-div-1_1">
                            <img style={{ height: "70px", width: "75px", marginBottom: "17px" }} src={data.image} alt="" />
                            <h4>{data.title}</h4>
                            <span >{data.desc}</span>
                        </div>
                    ))
                }


            </div>
            <p className='mt-5 pb-10 pb-5 text-center' style={{ color: "black", fontWeight: "bold", letterSpacing: "0.4px", fontSize: "1.15rem", marginBottom: "0" }}>Thank you for being a part of Farmer Connect. Together, we can create a thriving future for agriculture.</p>

        </div>
    )
}

export default OurServices



{/* {
                    services?.map((data, index) => (
                        <div key={index} style={{ backgroundImage: `url(${data.image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center" }} className=' '>
                            <div className='our-services-div-1_2'>
                                <h4 className='pb-2' style={{ color: "white", textAlign: "center" }}>{data?.title}</h4>
                                <span className='text-center' style={{ color: "white" }}> {data?.desc} </span>
                            </div>
                        </div>
                    ))
                } */}