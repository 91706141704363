import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import PhoneImgIcon from '../../assets/images/icon/phone.png';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Weather from '../../components/weather'
import WeatherImg from '../../assets/images/weather.png'
import axios from 'axios';
import { MdCancel } from 'react-icons/md';

function MyVerticallyCenteredModal(props) {
    return (

        <div className='weathermodel'>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body style={{ padding: "0px" }} >
                <div onClick={props.onHide} style={{position:"absolute",right:"10px",top:"10px",cursor:"pointer"}}>
                        <MdCancel style={{color:"#ffffff",fontSize:"30px"}}/>
                    </div>
                    <Weather />
                </Modal.Body>

            </Modal>
        </div>

    );
}





const HeaderBottomTwo = () => {

    const [weatherpopup, setWeatherPopUp] = useState(false)
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);
    const [temp, setTemp] = useState("")

    const getLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const apiKey = "5f91081cc4043205b3afeb835cb4664e"
                    const resData = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${apiKey}&units=metric`)
                    console.log("res", resData?.data)
                    setTemp(resData?.data)
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setError(null);
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    useEffect(() => {
        getLocation()
    }, [])

    return (
        <div id="sticky-header" className="header-bottom">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-2 col-lg-2 col-md-6 col-6">
                        <Logo />
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-6">
                        <div className="header-call-btn f-right">
                            {/* <Link to="/"><img src={PhoneImgIcon} alt="Phone Icon" />+88 015848651</Link> */}
                            <div style={{ borderRadius: "10px", cursor: "pointer" }} onClick={() => setWeatherPopUp(true)} className="l-btn">
                                {/* <img src={WeatherImg} style={{ height: "28px", width: "35px", marginRight: "0" }} /> */}
                                {temp?.main?.temp ?
                                    <>{Math.round(temp?.main?.temp)}&#176;C</>
                                    :

                                    <img src={WeatherImg} style={{ height: "28px", width: "35px", marginRight: "0" }} />
                                }
                            </div>
                        </div>
                        <Nav />
                    </div>
                    <div className="col-12">
                        <div className="mobile-menu">
                            <MobileMenu />
                        </div>
                    </div>
                </div>
            </div>

            <MyVerticallyCenteredModal
                show={weatherpopup}
                onHide={() => setWeatherPopUp(false)}
            />
        </div>
    )
}

export default HeaderBottomTwo;