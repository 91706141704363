import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import HeroStyleOne from "../components/hero/HeroStyleOne";
import ServiceStyleOne from "../components/services/ServiceStyleOne";
import HomeOneAbout from "../components/home1/HomeOneAbout";
import HomeOneService from "../components/home1/HomeOneService";
import FunFact from "../components/fun-fact/FunFact";
import ProjectStyleOne from "../components/project/ProjectStyleOne";
import WhyChoseUsOne from "../components/why-choose-us/WhyChoseUsOne";
import TestimonialStyleOne from "../components/testimonial/TestimonialStyleOne";
import HomeOneBookAppointment from "../components/home1/HomeOneBookAppointment";
import HowItWorkOne from "../components/how-it-work/HowItWorkOne";
import BlogStyleOne from "../components/blog/BlogStyleOne";
import FooterOne from "../common/footer/FooterOne";
import Seeds from "../components/home1/Seeds";
import Category from "./Category";
import Mission from "./Mission";
import Corevalues from "./Corevalues";
import Whyfmc from "./Whyfmc";
import Vision from "./Vision";
import Fmcsupermarket from "./Fmcsupermarket";
import HeroStyleThree from "../components/hero/HeroStyleThree";
import TestimonialStyleTwo from "../components/testimonial/TestimonialStyleTwo";
import Benefits from "./Benefits";
import Temporary from './Temporary'
import HeaderstyleFour from "../components/hero/HeaderstyleFour";
import HeaderBottomThree from "../common/header/HeaderBottomThree";
import Customerreview from "./Customerreview";
import Subscribe from "../components/subscribe/Subscribe";



const HomeDefault = () => {
    return (
        <>
            {/* <HeaderTop /> */}
            {/* <HeaderBottomOne /> */}
            <HeaderBottomThree />
            <HomeOneAbout />
            <Category />
            <Benefits />
            <Fmcsupermarket />
            <Customerreview />
            <Subscribe />



            {/*<TestimonialStyleTwo />  */}
            {/* <HeroStyleOne /> */}
            {/* <Corevalues />               our core values */}
            {/* <ServiceStyleOne /> */}
            {/* <Whyfmc /> */}
            {/* <Vision/> */}
            {/* <Mission/> */}
            {/* <BlogStyleOne /> */}
            {/* <Seeds/> */}
            {/* <HomeOneService /> */}
            {/* <FunFact /> */}
            {/* <ProjectStyleOne /> */}
            {/* <WhyChoseUsOne /> */}
            {/* <TestimonialStyleOne /> */}
            {/* <HomeOneBookAppointment /> */}
            {/* <HowItWorkOne /> */}
            <FooterOne />
        </>
    )
}

export default HomeDefault;