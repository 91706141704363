import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import WhyChooseUsBg from '../../assets/images/why-choose-us/bg-1.jpg';
import Icon_Estimates from '../../assets/images/why-choose-us/estimates.png';
import Icon_Equipment from '../../assets/images/why-choose-us/equipment.png';
import Icon_Work from '../../assets/images/why-choose-us/work.png';
import Icon_Irreigation from '../../assets/images/why-choose-us/irreigation.png';

const WhyChooseUsOneData = [
    {
        id: uuidv4(),
        heading: 'Farm Empowerment',
        description: 'Empowering farmers for sustainable futures',
        img: Icon_Estimates,
    },
    {
        id: uuidv4(),
        heading: 'Community Connection',
        description: 'Building stronger communities through food.',
        img: Icon_Equipment,
    },
    {
        id: uuidv4(),
        heading: 'Sustainable Sourcing',
        description: 'Ensuring sustainability throughout sourcing',
        img: Icon_Work,
    },
    {
        id: uuidv4(),
        heading: 'Accessible Agriculture',
        description: 'Making agriculture accessible and inclusive',
        img: Icon_Irreigation,
    },

];

const WhyChooseUsOneItem = ({ heading, description, img }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-6">
                <div style={{ borderRadius: "10px" }} className="single-choose-us">
                    <div className="pl-30 pb-30 bb-1  b-color-2">
                        <img src={img} alt="" />
                    </div>
                    <div className="single-choose-us-content pt-40">
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

const WhyChoseUsOne = () => {
    return (
        <>
            <div className="height-592 bg-property pt-110 pb-110" data-overlay="theme-1" data-opacity="7" style={{ backgroundImage: `url(https://photos.peopleimages.com/picture/202301/2766511-business-people-hands-or-thumbs-up-in-office-success-of-marketing-ideas-branding-innovation-target-or-advertising-achievement-goals.-zoom-men-or-women-and-yes-gesture-in-creative-designer-company-fit_400_400.jpg)` }}>
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                {/* <span>Why Choose US</span> */}
                                <h2 className="white-color">Our Missions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row position-relative">
                        {WhyChooseUsOneData.map((data) => (
                            <WhyChooseUsOneItem
                                key={data.id}
                                heading={data.heading}
                                description={data.description}
                                img={data.img}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyChoseUsOne;