import React from 'react';
import { FaQuoteLeft } from "react-icons/fa";
import Slider from "react-slick";
import TestimonialStyleTwoImg1 from '../../assets/images/testimonial/testimonial-1.png';

const TestimonialStyleTwo = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear',
    };
    return (
        <>
            <div className="testimonial-area white-bg pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div data-aos="slide-down" className="section-title text-center mb-70">
                                <span>Happy Customer</span>
                                <h2>What Our Customer Says <br /> About Our Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-end">
                        <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6">
                            <div className="testimonial-img res-imge-class">
                                <img src="https://e0.pxfuel.com/wallpapers/571/411/desktop-wallpaper-farm-indian-agriculture.jpg" alt="" />
                            </div>
                        </div>
                        <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6">
                            <div className="testimonial-style-2-wrapper">
                                <Slider {...settings} className="testimonial-style-2">
                                    <div className="single-testimonial-style-2">
                                        <p>Farmer Connect has transformed how I connect with my customers. The platform's user-friendly interface and commitment to sustainability have significantly boosted my business. I highly recommend it to any farmer looking to expand their reach.</p>
                                        <h4>John Thompson</h4>
                                        <span>Organic Farmer</span>
                                    </div>
                                    <div className="single-testimonial-style-2">
                                        <p>Thanks to Farmer Connect, I can now offer my customers a wider variety of fresh, locally-sourced produce. The direct connection with farmers ensures quality and transparency, which my customers truly appreciate.</p>
                                        <h4>Mokadesh Chowdhury</h4>
                                        <span>Local Market Vendor</span>
                                    </div>
                                    <div className="single-testimonial-style-2">
                                        <p>Farmer Connect's focus on ethical sourcing and innovation aligns perfectly with my values. The platform has made it easier for me to support and promote responsible farming practices, benefiting both farmers and consumers.</p>
                                        <h4>David Singh</h4>
                                        <span>CEO, Fast Garden</span>
                                    </div>
                                </Slider>
                                <div className="testimonial-quote-icon">
                                    <FaQuoteLeft />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestimonialStyleTwo;