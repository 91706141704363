import React from 'react'
import farmer from './../assets/images/farmer.png'
import buyer from './../assets/images/buyer.png'
import seller from './../assets/images/seller.png'
import { Grid } from '@mui/material'

const Benefits = () => {
    return (
        <div className='benefits-divv'>
            <div className='b-div-1'>
                {/* <h2 data-aos="fade-down" className='pb-5 text-center'>Benefits</h2> */}
                <h2 className='pb-5 text-center'>Benefits</h2>
                {/* <div className='b-div-2'>

                    <div data-aos="fade-up-right" className='b-div-2_1'>
                        <div className="outer-border">
                            <div className="inner-border">
                                <img src={farmer} alt="Trophy" className="trophy-image" />
                            </div>
                        </div>

                        <div className='b-div-2_1_1'>
                            <h3>For Farmers</h3>

                            <ul>
                                <li>Increased market access and higher profit margins by eliminating middlemen.</li>
                                <li> Access to a broader range of buyers and better pricing options.</li>
                                <li>Availability of essential agricultural inputs and equipment at competitive prices.</li>
                            </ul>

                        </div>
                    </div>

                    <div data-aos="fade-left" className='b-div-2_1'>
                        <div style={{ border: " 12px solid #10bebf" }} className="outer-border">
                            <div className="inner-border">
                                <img src={buyer} alt="Trophy" className="trophy-image" />
                            </div>
                        </div>

                        <div className='b-div-2_1_2'>
                            <h3>For Buyers</h3>
                            <ul>
                                <li>Assurance of fresh and quality produce directly from the source.</li>
                                <li>Competitive pricing through direct negotiations and bulk buying options.</li>
                                <li>Convenient and transparent purchasing process with reliable delivery.</li>
                            </ul>

                        </div>
                    </div>

                    <div data-aos="fade-right" className='b-div-2_1'>
                        <div style={{ border: "12px solid #69c790" }} className="outer-border">
                            <div className="inner-border">
                                <img style={{ height: "120px", width: "120px" }} src={seller} alt="Trophy" className="trophy-image" />
                            </div>
                        </div>

                        <div className='b-div-2_1_3'>
                            <h3>For Sellers</h3>
                            <ul>
                                <li>Direct access to a large customer base of farmers.</li>
                                <li>Opportunities to expand market reach and increase sales.</li>
                                <li> Platform to educate farmers about new products and technologies</li>
                            </ul>

                        </div>
                    </div>
                </div> */}





                <div className='b-div-3'>
                    <Grid container spacing={2}>
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className='b-div-3_1'>
                                <div className='b-div-3_2'>
                                    <h3>For Farmers</h3>
                                    <img style={{ height: "80px", width: "80px" }} src={farmer} alt="" />
                                </div>
                                <ul>
                                    <li>Increased market access and higher profit margins by eliminating middlemen.</li>
                                    <li> Access to a broader range of buyers and better pricing options.</li>
                                    <li>Availability of essential agricultural inputs and equipment at competitive prices.</li>
                                </ul>

                            </div>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>

                            <div className='b-div-3_1'>
                                <div className='b-div-3_2'>
                                    <h3>For Buyers</h3>
                                    <img style={{ height: "80px", width: "80px" }} src={buyer} alt="" />
                                </div>
                                <ul>
                                    <li>Assurance of fresh and quality produce directly from the source.</li>
                                    <li>Competitive pricing through direct negotiations and bulk buying options.</li>
                                    <li>Convenient and transparent purchasing process with reliable delivery.</li>
                                </ul>

                            </div>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>

                            <div className='b-div-3_1'>
                                <div className='b-div-3_2'>
                                    <h3>For Sellers</h3>
                                    <img style={{ height: "80px", width: "80px" }} src={seller} alt="" />
                                </div>
                                <ul>
                                    <li>Direct access to a large customer base of farmers.</li>
                                    <li>Opportunities to expand market reach and increase sales.</li>
                                    <li> Platform to educate farmers about new products and technologies</li>
                                </ul>

                            </div>
                        </Grid>
                    </Grid>
                </div>







            </div>
        </div>
    )
}

export default Benefits