import React from 'react'
import empower from '../assets/images/empowering.png'
import { alignPropType } from 'react-bootstrap/esm/types'
import group from '../assets/images/group.png'
import earth from '../assets/images/earth.png'
import control from '../assets/images/control.png'

const Mission = () => {
  return (
    <div className='mission-div'>
      {/* <h1 className='text-center pb-30 pt-30'>Our Mission and Vision</h1> */}
      <div className='mission-div-1'>
        <div data-aos="slide-right" className='mission-div-1_1' >
          <div style={{ padding: "8px", backgroundColor: "white", borderRadius: "50%", boxShadow: "rgba(50, 50, 93, 0.25) 0px 0px 20px 3px, rgba(0, 0, 0, 0.3) 0px -1px 13px 3px" }}>
            <img height={70} width={70} src={empower} alt="" />
          </div>
          <div className='text-center'>
            <h5>Empowering Farmers</h5>
            <span>Cultivating Sustainable Futures for Farmers' Prosperity</span>
          </div>
        </div>


        <div data-aos="zoom-in-up" className='mission-div-1_1' >
          <div style={{ padding: "13px", backgroundColor: "white", borderRadius: "50%", boxShadow: "rgba(50, 50, 93, 0.25) 0px 0px 20px 3px, rgba(0, 0, 0, 0.3) 0px -1px 13px 3px" }}>
            <img height={60} width={60} src={group} alt="" />
          </div>
          <div className='text-center'>
            <h5>Community Connection</h5>
            <span>Strengthening Social Bonds Through Local Food Networks</span>
          </div>
        </div>



        <div data-aos="zoom-in-up" className='mission-div-1_1' >
          <div style={{ padding: "13px 10px 12px 16px", backgroundColor: "white", borderRadius: "50%", boxShadow: "rgba(50, 50, 93, 0.25) 0px 0px 20px 3px, rgba(0, 0, 0, 0.3) 0px -1px 13px 3px" }}>
            <img height={60} width={60} src={earth} alt="" />
          </div>
          <div className='text-center'>
            <h5>Sustainable Sourcing</h5>
            <span>Ethical and Transparent Supply Chains for Environmental Responsibility</span>
          </div>
        </div>



        <div data-aos="slide-left" className='mission-div-1_1' >
          <div style={{ padding: "13px", backgroundColor: "white", borderRadius: "50%", boxShadow: "rgba(50, 50, 93, 0.25) 0px 0px 20px 3px, rgba(0, 0, 0, 0.3) 0px -1px 13px 3px" }}>
            <img height={60} width={60} src={control} alt="" />
          </div>
          <div className='text-center'>
            <h5>Accessible Agriculture</h5>
            <span>Promoting Inclusive Food Access for Every Community's Well-being</span>
          </div>
        </div>
      </div>

      <div className='mission-div-2'>
        <div className='mission-div-2_1 text-center'>
          <div data-aos="slide-right" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2>Our Mission</h2>
            <div style={{ height: "9px", width: "70px", backgroundColor: "#aaccb8", borderRadius: "5px" }}>

            </div>
          </div>
          <span data-aos="slide-up" style={{ color: "black" }}>At the heart of our mission lies a commitment to empowering farmers and strengthening community bonds through sustainable agriculture. We strive to provide farmers with the resources, support, and opportunities they need to thrive, cultivating sustainable futures for generations to come. Through locally sourced, community-driven food initiatives, we aim to foster collaboration, resilience, and shared experiences, creating stronger social bonds and promoting inclusivity in agricultural access</span>
        </div>
        <div className='mission-div-2_2 text-center'>
          <div data-aos="slide-left" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2>Our Vision</h2>
            <div style={{ height: "9px", width: "70px", backgroundColor: "#aaccb8", borderRadius: "5px" }}>

            </div>
          </div>
          <span data-aos="slide-up" style={{ color: "black" }}>Our vision is one of a future where farmers are empowered to thrive in sustainable agriculture, supported by resilient community networks built on collaboration and shared values. We aspire to create supply chains that are not only ethical and transparent but also environmentally responsible, promoting accountability and sustainability in every aspect of our operations. Through our efforts to promote inclusive food access, we envision a world where everyone has access to fresh, locally sourced food. </span>
        </div>
      </div>
    </div>
  )
}

export default Mission