import React from 'react'
// import { FaThermometerEmpty } from "@react-icons/all-files/fa/FaThermometerEmpty"
import { GiSunrise, GiSunset } from "react-icons/gi";
import { FaThermometerEmpty } from "react-icons/fa";
import { MdKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { PiDropHalfFill } from "react-icons/pi";
import { FiWind } from "react-icons/fi";




const TempandDetail = ({
    weather:
    {
        details,
        icon,
        temp,
        temp_min,
        temp_max,
        sunrise,
        sunset,
        humidity,
        feels_like,
        speed
    }
}) => {

    const HorizontalDetails = [
        {
            id: 1,
            Icon: GiSunrise,
            title: "Sunrise",
            value: sunrise
        },
        {
            id: 2,
            Icon: GiSunset,
            title: "Sunset",
            value: sunset
        },
        {
            id: 3,
            Icon: MdKeyboardArrowUp,
            title: "High",
            value: `${temp_max.toFixed()}°`
        }
        ,
        {
            id: 3,
            Icon: MdOutlineKeyboardArrowDown,
            title: "Low",
            value: `${temp_min.toFixed()}°`
        }
    ]

    const VerticalDetails = [
        {
            id: 1,
            Icon: FaThermometerEmpty,
            title: "Real Feel",
            value: `${feels_like.toFixed()}°`
        },
        {
            id: 2,
            Icon: PiDropHalfFill,
            title: "humidity",
            value: `${humidity.toFixed()}%`
        },
        {
            id: 3,
            Icon: FiWind,
            title: "wind",
            value: `${speed.toFixed()} km/h`
        }
    ]


    return (
        <div className='tempanddetail-div'>
            <span className='cw'>{details}</span>
            <div className='tempanddetail-div-1'>
                <img style={{ height: "65px", width: "65px" }} src={icon} alt="" />
                <span className='cw' style={{ fontSize: "1.8rem" }}>{`${temp.toFixed()}°`}</span>
                <div className='tempanddetail-div-1_3'>
                    {
                        VerticalDetails?.map((data) => (
                            <div style={{ textAlign: "left" }} key={data.id}>
                                <data.Icon className='cw' style={{ marginLeft: "18px" }} />
                                <span className='cw' style={{ paddingLeft: "8px" }}>{data.title}</span>
                                <span className='cw'> {data.value}</span>
                            </div>
                        ))
                    }
                </div>
            </div>


            <div className='tempanddetail-div-2'>
                {
                    HorizontalDetails?.map((data) => (
                        <div key={data.id}>
                            <data.Icon className='cw' style={{ fontSize: "1.2rem" }} />
                            <span className='cw' style={{ paddingLeft: "8px" }}>{data.title}</span>
                            <span className='cw' style={{ paddingLeft: "2px" }}> {data.value}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TempandDetail