import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP } from 'react-icons/fa';
import BlogAuthorAvatar from '../../assets/images/blog/author-avatar.jpg';

const BlogAuthor = () => {
    return (
        <div className="row mt-95">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="post-author-avatar pl-30">
                    <img className="img-fluid" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUQuzgnMFPysKgiWW0aicFs_YlKLPAVDWBbcXRiz_S2FbLNo1AQQf-U_hjl-6OwMP8luE&usqp=CAU'} alt="" />
                </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
                <div className="post-author-bio">
                    <h5><Link to="/">Balan Pillai</Link></h5>
                    <p>
                        Balan Pillai is a passionate advocate for sustainable agriculture and the founder and CEO of Farmer Connects. With over 20 years of experience in the farming industry, Balan Pillai has dedicated his career to helping farmers embrace modern technology and sustainable practices.

                    </p>
                    <Link to="/"><FaFacebookF /></Link>
                    <Link to="/"><FaTwitter /></Link>
                    <Link to="/"><FaPinterestP /></Link>
                </div>
            </div>
        </div>
    )
}

export default BlogAuthor;