import React, { useRef, useEffect } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import { PiStarFill } from "react-icons/pi";
import { RiDoubleQuotesL } from "react-icons/ri";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Brahmaiah from '../assets/images/Brahmaiah.jpg'
import Shankar from '../assets/images/Shankar.jpg'

const Customerreview = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.navigation.prevEl = prevRef.current;
            swiperRef.current.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    return (
        <div className='customer-review'>
            <h1 className='text-center pb-30'>What our customer say</h1>

            <div style={{ paddingBottom: "40px" }} className='customer-review-1'>
                {/* <h1>WHAT OUR CUSTOMER SAY</h1> */}

                {/* <div>
                    <button ref={prevRef} style={{ marginRight: "10px" }}>
                        <MdOutlineKeyboardArrowLeft style={{ fontSize: "1.6rem" }} />
                    </button>
                    <button ref={nextRef}>
                        <MdOutlineKeyboardArrowRight style={{ fontSize: "1.6rem" }} />
                    </button>
                </div> */}
            </div>

            <Swiper
                modules={[Navigation]}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                breakpoints={{
                    320: { slidesPerView: 1 },
                    450: { slidesPerView: 1 },
                    638: { slidesPerView: 2 },
                    950: { slidesPerView: 3 },
                    1335: { slidesPerView: 3 },
                    1586: { slidesPerView: 5 },
                    1800: { slidesPerView: 6 },
                    2250: { slidesPerView: 7 },
                }}
                loop={true}
                slidesPerView={4}
                autoplay
                spaceBetween={40}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current
                }}
                speed={1500}
            >
                <SwiperSlide>
                    <div className='card-div5'>
                        <div className='card-div6'>
                            <div className='star-div'>
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                            </div>
                            <div className='text-div'>
                                <p>
                                    “The application is very user-friendly and easy to navigate. The interface is intuitive, making it simple to list products for sale and browse items to purchase.”
                                </p>
                            </div>
                            <div className='detail-div'>
                                <img style={{ height: "80px", width: "80px", borderRadius: "50%" }} src={Brahmaiah} alt="" />
                                <div>
                                    <h6>Brahmaiah</h6>
                                    <span>Business Man</span>
                                </div>
                            </div>
                        </div>
                        <div className='corner1'>
                            <div className='dots'>
                                <RiDoubleQuotesL style={{ color: "#404a3d", fontSize: "2rem" }} />
                            </div>
                            <div style={{ top: "-2px" }} className='pbmit-sticky-corner pbmit-top-left-corner'>
                                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 35V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                            <div className='pbmit-sticky-corner pbmit-top-right-corner'>
                                <svg width="30" height="30" fill viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className='card-div5'>
                        <div className='card-div6'>
                            <div className='star-div'>
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                            </div>
                            <div className='text-div'>
                                <p>
                                    “Transactions are smooth and efficient. The app ensures that buying and selling processes are quick, with clear instructions and minimal steps involved.”
                                </p>
                            </div>
                            <div className='detail-div'>
                                <img style={{ height: "80px", width: "80px", borderRadius: "50%" }} src={Shankar} alt="" />
                                <div>
                                    <h6>Shankar</h6>
                                    <span>Business Man</span>
                                </div>
                            </div>
                        </div>
                        <div className='corner1'>
                            <div className='dots'>
                                <RiDoubleQuotesL style={{ color: "#404a3d", fontSize: "2rem" }} />
                            </div>
                            <div style={{ top: "-2px" }} className='pbmit-sticky-corner pbmit-top-left-corner'>
                                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 35V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                            <div className='pbmit-sticky-corner pbmit-top-right-corner'>
                                <svg width="30" height="30" fill viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className='card-div5'>
                        <div className='card-div6'>
                            <div className='star-div'>
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                            </div>
                            <div className='text-div'>
                                <p>
                                    “The platform has a wide user base, which increases the chances of selling products quickly. It's great to have access to a large network of buyers and sellers.”
                                </p>
                            </div>
                            <div className='detail-div'>
                                <img style={{ height: "80px", width: "80px", borderRadius: "50%" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRj2ty2YzT4n6fMr9cbogjV4iAvVNhxncXTg&s" alt="" />
                                <div>
                                    <h6>Keshavan Namboothiri</h6>
                                    <span>Business Man</span>
                                </div>
                            </div>
                        </div>
                        <div className='corner1'>
                            <div className='dots'>
                                <RiDoubleQuotesL style={{ color: "#404a3d", fontSize: "2rem" }} />
                            </div>
                            <div style={{ top: "-2px" }} className='pbmit-sticky-corner pbmit-top-left-corner'>
                                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 35V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                            <div className='pbmit-sticky-corner pbmit-top-right-corner'>
                                <svg width="30" height="30" fill viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className='card-div5'>
                        <div className='card-div6'>
                            <div className='star-div'>
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                                <PiStarFill style={{ color: "rgb(253 207 0)", fontSize: "1.3rem", marginRight: "3px" }} />
                            </div>
                            <div className='text-div'>
                                <p>
                                    “The application provides secure payment options, giving peace of mind when conducting transactions. Knowing that my payments are safe is a big plus.”
                                </p>
                            </div>
                            <div className='detail-div'>
                                <img style={{ height: "80px", width: "80px", borderRadius: "50%" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUQuzgnMFPysKgiWW0aicFs_YlKLPAVDWBbcXRiz_S2FbLNo1AQQf-U_hjl-6OwMP8luE&usqp=CAU" alt="" />
                                <div>
                                    <h6>Balan Pillai</h6>
                                    <span>Business Man</span>
                                </div>
                            </div>
                        </div>
                        <div className='corner1'>
                            <div className='dots'>
                                <RiDoubleQuotesL style={{ color: "#404a3d", fontSize: "2rem" }} />
                            </div>
                            <div style={{ top: "-2px" }} className='pbmit-sticky-corner pbmit-top-left-corner'>
                                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 35V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                            <div className='pbmit-sticky-corner pbmit-top-right-corner'>
                                <svg width="30" height="30" fill viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="red" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>



            </Swiper>
        </div>
    );
};

export default Customerreview;
