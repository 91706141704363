import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from "swiper";

// import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";


const Seeds = () => {

  return (

    <div style={{ paddingTop: "2rem" }}>
      <div className="section-title text-center mb-70">
        <h2>Seeds</h2>
        <span style={{width:"70%",margin:"auto",fontSize:"1.1rem"}}>
        The seed category encompasses a diverse range of plant seeds, offering varieties suited for various climates, soil types, and growing conditions, facilitating the cultivation of a wide array of crops in agricultural practices
        </span>
      </div>
      <Swiper

        breakpoints={{
          320: { slidesPerView: 2 },
          450: { slidesPerView: 3 },
          638: { slidesPerView: 4 },
          991: { slidesPerView: 5 },
          1300: { slidesPerView: 6 },
          1800: { slidesPerView: 7 }


        }}
        // modules={[Autoplay, Pagination, Navigation]}
        // loop={(movies?.length > 4) ? true : false}
        autoplay={{
          delay: 6000,
        }}
        speed={650}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: 'swiper-buton-next',
          prevEl: 'swiper-button-pre',
          clickable: true
        }}
        loop={true}
        slidesPerView={6}
        spaceBetween={12}
        as="ul"
      >

        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://images.jdmagicbox.com/rep/b2b/organic-vegetable-seed/organic-vegetable-seed-7.jpg" alt={'cast'} />
            <span className="seed-text">Vegetable Seeds</span>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://static01.nyt.com/images/2018/05/23/dining/23fruit/23fruit-superJumbo.jpg" alt={'cast'} />
            <span className="seed-text">Fruit Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE0hDi-tJNBtfp80ReHWaP66o9dmiORHv9rhTpv_inwQ&s" alt={'cast'} />
            <span className="seed-text">Grain Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://static.whatsinseason.com.au/canvas/images/DH2wC5t6NqVwqzdKpYeuCEB4O1mEPXiCNhUQxBBs.jpg" alt={'cast'} />
            <span className="seed-text">Legume  Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://images.pexels.com/photos/56866/garden-rose-red-pink-56866.jpeg" alt={'cast'} />
            <span className="seed-text">Flower Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://www.agrifarming.in/wp-content/uploads/2021/12/Herb_Farming_Tips4-1024x684.jpg" alt={'cast'} />
            <span className="seed-text">Herb Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUB20HCuMKxot51YGayr70fd072VkM_pOLuGVL1ZzGIQ&s" alt={'cast'} />
            <span className="seed-text">Tree Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://www.shutterstock.com/image-photo/park-shrubs-green-lawns-landscape-260nw-329291891.jpg" alt={'cast'} />
            <span className="seed-text">Shrub  Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmyqghI8DF_2qqi3OFJ2l45CTOq8BZyFUBgr70Tv9Ktg&s" alt={'cast'} />
            <span className="seed-text">Native Seeds</span>
          </div>
        </SwiperSlide>


        <SwiperSlide style={{ textAlign: "center" }} as="li">
          <div style={{ marginBottom: "15px" }} >
            <img style={{ borderRadius: "10px", width: "100%", height: "140px", marginBottom: "15px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS88k_6WnSajzMIr68LybIt2SHIAEf3qQxgxOQeN2otHQ&s" alt={'cast'} />
            <span className="seed-text">Exotic Seeds</span>
          </div>
        </SwiperSlide>
  





      </Swiper>
    </div>

  );
}

export default Seeds