import { DateTime } from "luxon"

const BASE_URL = "https://api.openweathermap.org/data/2.5/"
const API_KEY = "19b0dc8f50c884193ded80b93e2df6c3"




const getWeatherData = (infotype, searchParams) => {
    const url = new URL(BASE_URL + infotype)
    url.search = new URLSearchParams({ ...searchParams, appid: API_KEY })

    return fetch(url)
        .then((res) => res.json())
        .then((data) => data)
}
// export default getWeatherData;





const formatCurrentWeather = (data) => {
    const {
        coord: { lat, lon },
        main: { temp, temp_min, temp_max, feels_like, pressure, humidity },
        dt,
        name,
        sys: { country, sunrise, sunset },
        weather,
        wind: { speed },
        timezone
    } = data

    const { main: details, icon } = weather[0]
    const formattedLocalTime = formatToLocalTime(dt, timezone)

    return {
        lat,
        lon,
        temp,
        temp_min,
        temp_max,
        feels_like,
        pressure,
        humidity,
        country,
        sunrise: formatToLocalTime(sunrise, timezone, "hh:mm a"),
        sunset: formatToLocalTime(sunset, timezone, "hh:mm a"),
        dt,
        timezone,
        name,
        speed,
        details,
        icon: iconUrlFromCode(icon),
        formattedLocalTime
    }
}


const formatForcastWeather = (secs, offset, data) => {

    let { hourly, daily } = data

    daily = data?.filter((f) => f.dt_txt.slice(-8) === "00:00:00")?.map(d => {
        return {
            title: formatToLocalTime(d.dt, offset, "ccc"),
            temp: d.main.temp,
            icon: iconUrlFromCode(d.weather[0].icon),
            date: d.dt_txt
        }
    }).slice(0, 5)




    hourly = data?.filter((d) => d.dt > secs)?.slice(0, 5).map(d => {
        return {
            title: formatToLocalTime(d.dt, offset, "hh:mm a"),
            temp: d.main.temp,
            icon: iconUrlFromCode(d.weather[0].icon),
            date: d.dt_txt
        }
    })

    return { hourly, daily }
}


const getformattedWeatherData = async (searchParams) => {
    const formattedCurrentWeather = await getWeatherData("weather", searchParams)
        .then(formatCurrentWeather)

    const { dt, lat, lon, timezone } = formattedCurrentWeather

    const formattedForcastWeather = await getWeatherData('forecast',
        {
            lat,
            lon,
            units: searchParams.units
        }).then((d) => formatForcastWeather(dt, timezone, d.list))

    return { ...formattedCurrentWeather, ...formattedForcastWeather }
}


const formatToLocalTime = (secs, offset, format = "cccc,dd LLL 2024' | Local Time 'hh:mm a") => DateTime.fromSeconds(secs + offset, { zone: 'utc' }).toFormat(format)

const iconUrlFromCode = (icon) => `http://openweathermap.org/img/wn/${icon}@2x.png`;

export default getformattedWeatherData;