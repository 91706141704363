import React from 'react'
import animal from '../assets/images/category/animal.png'
import buyer from '../assets/images/category/buyer.png'
import crops from '../assets/images/category/crops.png'
import farming from '../assets/images/category/farming.png'
import labout from '../assets/images/category/labout.png'
import machinary from '../assets/images/category/machinary.png'
import seed from '../assets/images/category/seed.png'
import storage from '../assets/images/category/storage.png'
import vegetable from '../assets/images/category/vegetable.png'
import transportation from '../assets/images/category/transportation.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


const categories = [
    {
        image: "https://media.istockphoto.com/id/543212762/photo/tractor-cultivating-field-at-spring.jpg?s=612x612&w=0&k=20&c=uJDy7MECNZeHDKfUrLNeQuT7A1IqQe89lmLREhjIJYU=",
        name: "Farm Lands"
    },
    {
        image: "https://images.nationalgeographic.org/image/upload/t_edhub_resource_key_image/v1638892233/EducationHub/photos/crops-growing-in-thailand.jpg",
        name: "Crops"
    },
    {
        image: "https://bsmedia.business-standard.com/_media/bs/img/article/2020-04/20/full/1587366222-4915.png",
        name: "Seeds"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIp6D_8b13X-nU-9iTi3Qx1BuKbvX8esPmlLdP70OCmTeMuBF-zMHqcBIaQhzqxN5_ntg&usqp=CAU",
        name: "Vegetables & Fruits"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKr0fXhtj35LzaHQHIBGBQRMlQOlCFx8lUyw&s",
        name: "Workers"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2Hl35-arvNQlfM6aEvmNC46Lm_yerW216Kg&s",
        name: "Transport"
    },
    {
        image: "https://assets.isu.pub/document-structure/230311101920-839c5223f11455a9a587ac243d479b00/v1/366fb4efb4073cce378027ca99d659b2.jpeg", // URL for Machinary background if any
        name: "Machinary"
    },
    {
        image: "https://www.owlguru.com/wp-content/uploads/wpallimport/files/buyers-and-purchasing-agents-farm-products/__(1).jpg", // URL for Buyers background if any
        name: "Buyers"
    },
    {
        image: "https://i0.wp.com/indianinfrastructure.com/wp-content/uploads/2020/08/II-54.jpg?fit=1438%2C646&ssl=1",
        name: "Storage"
    },
    {
        image: "https://www.zarebasystems.com/media/Articles/Zareba/5-most-profitable-animals-for-small-farms.jpg",
        name: "Animals"
    },
    {
        image: "https://assets1.chainstoreage.com/styles/hero/s3/teaser_image_39815.jpg",
        name: "Mega Store"
    }
];


const Category = () => {
    return (
        <div className='category-div'>
            {/* <div className='work-with-div'>
                    <h1 data-aos="slide-up" style={{ color: "black", textAlign: "center", paddingTop: "2rem" }}>Categories We cover </h1>
                    <p data-aos="slide-up" style={{ color: "black" }}>Farmer Connects is a comprehensive digital platform designed to bridge the gap between farmers, buyers, and sellers, fostering an ecosystem where all agricultural stakeholders can thrive. This marketplace aims to streamline the agricultural supply chain, improve market access, and ensure fair pricing for both farmers and buyers. </p>
                    <div className='work-with'>
                        <div data-aos="slide-right" style={{ backgroundSize: "cover", backgroundImage: "url(https://media.istockphoto.com/id/543212762/photo/tractor-cultivating-field-at-spring.jpg?s=612x612&w=0&k=20&c=uJDy7MECNZeHDKfUrLNeQuT7A1IqQe89lmLREhjIJYU=)" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Farming</h3>
                            </div>
                        </div>



                        <div data-aos="zoom-in-up" style={{ backgroundSize: "cover", backgroundImage: "url(https://images.nationalgeographic.org/image/upload/t_edhub_resource_key_image/v1638892233/EducationHub/photos/crops-growing-in-thailand.jpg)" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Crops</h3>
                            </div>
                        </div>



                        <div data-aos="zoom-in-up" style={{ backgroundSize: "cover", backgroundImage: "url(https://bsmedia.business-standard.com/_media/bs/img/article/2020-04/20/full/1587366222-4915.png" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Seeds</h3>
                            </div>
                        </div>



                        <div data-aos="slide-left" style={{ backgroundSize: "cover", backgroundImage: "url(https://img.freepik.com/free-photo/woman-holding-basket-full-different-vegetables_23-2148580022.jpg" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Vegetables & Fruits</h3>
                            </div>
                        </div>



                        <div data-aos="slide-right" style={{ backgroundSize: "cover", backgroundImage: "url(https://images.indianexpress.com/2019/03/farm-labour.jpg" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Labour</h3>
                            </div>
                        </div>



                        <div data-aos="zoom-in-up" style={{ backgroundSize: "cover", backgroundImage: "url(https://bloximages.chicago2.vip.townnews.com/siouxcityjournal.com/content/tncms/assets/v3/editorial/b/6d/b6dbed68-5252-11ee-a55e-3fcab867ae2d/6454196b52cb1.image.jpg?resize=1200%2C360" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Transport</h3>
                            </div>
                        </div>




                        <div data-aos="zoom-in-up" className=' category-box Machinarybg'>
                            <div className='category-blur-box' >
                                <h3>Machinary</h3>
                            </div>
                        </div>


                        <div data-aos="slide-left" className='buyerbg category-box'>
                            <div className='category-blur-box' >
                                <h3>Buyers</h3>
                            </div>
                        </div>

                        <div data-aos="slide-right" style={{ backgroundSize: "cover", backgroundImage: "url(https://i0.wp.com/indianinfrastructure.com/wp-content/uploads/2020/08/II-54.jpg?fit=1438%2C646&ssl=1" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Storage</h3>
                            </div>
                        </div>


                        <div data-aos="zoom-in-up" style={{ backgroundSize: "cover", backgroundImage: "url(https://www.zarebasystems.com/media/Articles/Zareba/5-most-profitable-animals-for-small-farms.jpg)" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Animals</h3>
                            </div>
                        </div>


                        <div data-aos="zoom-in-up" style={{ backgroundSize: "cover", backgroundImage: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9HY631oBV9u-aN5UPcHUn5Dm7PermkpXwiAvYahmeiQ&s)" }} className=' category-box'>
                            <div className='category-blur-box' >
                                <h3>Mega Store</h3>
                            </div>
                        </div>

                    </div>
                </div> */}

            <div className='category-div-1'>
                {/* <h1 data-aos="slide-up" style={{ color: "black", textAlign: "center", paddingBottom: "40px", paddingTop: "2rem" }}>Categories We cover </h1> */}
                <h1 style={{ color: "#523906", textAlign: "center", paddingBottom: "40px", paddingTop: "2rem" }}>Categories We cover </h1>

                <Swiper
                    navigation={{
                        prevEl: '#prev',
                        nextEl: '#next'
                    }}
                    breakpoints={{
                        320: { slidesPerView: 1 },
                        450: { slidesPerView: 1 },
                        638: { slidesPerView: 2 },
                        950: { slidesPerView: 3 },
                        1335: { slidesPerView: 4 },
                        1586: { slidesPerView: 5 },
                        1800: { slidesPerView: 6 },
                        2250: { slidesPerView: 7 },

                    }}
                    loop={true}
                    slidesPerView={4}
                    spaceBetween={30}
                >



                    {
                        categories?.map((data, index) => (
                            <SwiperSlide>
                                <div className='card-div'>
                                    <div className='card-div-1'>
                                        <div className='overflow'>
                                            <img src={data?.image} alt="" />
                                        </div>
                                        {/* <ul style={{ marginTop: "25px" }}>
                                            <li style={{ listStyleType: "disc", color: "green" }}>{data?.name}</li>
                                        </ul> */}

                                        <h2 className='mt-3'>
                                            {data?.name}
                                        </h2>


                                    </div>
                                    <div className='space1'>
                                    </div>
                                    <div className='corner-icon'>
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))
                    }


                </Swiper>
            </div>

        </div>
    )
}

export default Category