import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon_landscape from '../../assets/images/service/landscape.png';
import Icon_growing from '../../assets/images/service/growing.png';
import Icon_harvest from '../../assets/images/service/harvest.png';
import Icon_flower from '../../assets/images/service/flower.png';
import Icon_tree from '../../assets/images/service/tree.png';
import Icon_rubbish from '../../assets/images/service/rubbish.png';
import { FaLongArrowAltRight } from 'react-icons/fa';

const ServiceOneData = [
    {
        id: uuidv4(),
        heading: 'Arable Farming',
        description: 'Arable farming: cultivating crops for maximum yield on open land.',
        img: "https://thumbs.dreamstime.com/b/arable-land-4408121.jpg",
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Horticulture',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: "https://5.imimg.com/data5/SELLER/Default/2022/9/UO/UF/GE/14251721/horticulture-farm-development-service.jpg",
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Mixed Farming',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdBqNpAOsEWY06fDED9CkBqSxn4uFMR_hCu0IYinDEKg&s",
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Organic Farming ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: "https://i.ndtvimg.com/i/2017-02/soil_620x350_81487334083.jpg",
        serviceLink: '/',
    }
    // {
    //     id: uuidv4(),
    //     heading: 'Agroforestry',
    //     description: 'There are many variations of passages the majority have suffered alteration form',
    //     img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCm0g6C86QmPsnwIrHx9SElq66Xv1donv3yMWpRMg6zA&s",
    //     serviceLink: '/',
    // }
];

const ServiceOneItem = ({ img, heading, description, serviceLink }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div style={{borderRadius:"10px"}} className="sub-category">
                    {/* <div className="service-icon">
                        <img src={img} alt="Service Icon" />
                    </div> */}
                    <div style={{ width: "100%",height:"170px" }}>
                        <img style={{height:"100%",width:"100%",borderRadius:"10px 10px 0 0"}} src={img} alt="" />
                    </div>
                    <div style={{padding:"25px"}}>
                        <h3>{heading}</h3>
                        <p>{description}</p>
                        <a href={serviceLink}>Read More <FaLongArrowAltRight /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

const ServiceStyleOne = () => {
    return (
        <>
            <div className="service-area theme-bg-4 pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                {/* <span>Our Services</span> */}
                                {/* <h2>Why will you choose our <br /> services?</h2> */}
                                <h2>Farming</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ServiceOneData.map((data) => (
                            <ServiceOneItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                                serviceLink={data.serviceLink}
                            />
                        ))}
                        {/* <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-service-large">
                                <h3>We are professional <span>Landscape & Gardening service</span></h3>
                                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat omnis voluptas assumenda est, omnis dolor repellendus. </p>
                                <a href="/" className="l-btn">Appointment</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceStyleOne;