import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowRight, FaHeadphones, FaEnvelopeOpen, FaMapMarkerAlt, FaClock } from "react-icons/fa";
import axios from 'axios';

const ContactForm = () => {
    const [form, setForm] = useState({})
    const [open, setOpen] = useState(null)
    const [loader, setLoader] = useState(false)
    const navigation = useNavigate()
    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault()
        console.log("form", form)
        const resData = await axios.post(`https://api.farmerconnects.com/api/contact_us/`, form)
        if (resData?.data?.statuscode == 200) {
            setOpen(resData?.data?.message)
            setLoader(false)
            // setForm({})

        }
        else {
            setOpen("Something went wrong !")
            setLoader(false)
            // setForm({})

        }
        setTimeout(() => {
            setOpen(null)
            navigation("/", { replace: true })
        }, 2000)
    }
    return (
        <>
            <div className="contact-area white-bg pt-110 pb-110">
                <div className="container">
                    <div className="box-shadow-3 pt-60 pb-60 pl-30 pr-30">
                        <div className="row">
                            <div data-aos="fade-right" className="col-xl-6 col-lg-6">
                                <div className="contact-form pr-70">
                                    <div className="section-title mb-40">
                                        <span>Need Our Service Or Have Queries</span>
                                        <h2>Send Us Message.</h2>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Your Name"
                                            value={form?.name}
                                            onChange={(e) => setForm({ ...form, name: e.target.value })}
                                            required
                                        />
                                        <input
                                            type="email"
                                            placeholder="Your Email"
                                            value={form?.email}
                                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                                            required
                                        />
                                        <input
                                            type="text"
                                            placeholder="Your Subject"
                                            value={form?.subject}
                                            onChange={(e) => setForm({ ...form, subject: e.target.value })}
                                            required
                                        />
                                        <textarea
                                            placeholder="Write Message Here"
                                            value={form?.message}
                                            onChange={(e) => setForm({ ...form, message: e.target.value })}
                                            required>

                                        </textarea>
                                        <button type='submit' disabled={loader ? true : false} className="l-btn">{loader ? "Sending..." : "Send Message"} <FaArrowRight /></button>
                                        {
                                            open && <div className='mt-3' style={{ fontWeight: "700", background: "#cb7e7e59", color: "#000000", padding: "20px", border: "1px solid red" }}>
                                                {open}
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                            <div data-aos="fade-left" className="col-xl-6 col-lg-6">
                                <div className="contact-box theme-bg-5  pt-60 pb-35 pl-35 pr-35 mr-40">
                                    <span className='cb'>Gardening Excellence!</span>
                                    <h3 className='cb'>Expanded Gardening <br /> Services Fits All needs!</h3>
                                    <p className='cb'>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms </p>
                                    {/* <ul className="mt-40"> */}
                                    {/* <li><FaHeadphones /><span  className='cb'>Emergency Line: (002) 012612457</span></li> */}
                                    <p >
                                        {/* <div><FaEnvelopeOpen style={{ color: "#000000" }} /></div> */}
                                        <span className='cb'>Email : fmcdigital2024@gmail.com</span>
                                    </p>
                                    <p>
                                        {/* <FaMapMarkerAlt style={{ color: "#000000" }} /> */}
                                        <span className='cb'>Location :
                                            MAHINDRA ASHVITHA
                                            HAFEEZ PET ROAD KPHB NEAR HITECH
                                            CITY MMTS KUKATPALLY, HYDERABAD
                                            TELANGANA - 500085</span></p>
                                    {/* <li><FaClock /><span className='cb'>Mon - Fri: 8:00 am - 7:00 pm</span></li> */}
                                    {/* </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="social-btn-area pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Facebook</Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Twitter</Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Google Plus</Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Pinterest</Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ContactForm;