import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/1.jpg';
import BlogStyleFour from '../components/blog/BlogStyleFour';
import Subscribe from '../components/subscribe/Subscribe';
import FooterTwo from '../common/footer/FooterTwo';
import FooterOne from '../common/footer/FooterOne';

const BlogList = () => {
    return (
        <>
            {/* <HeaderTop /> */}
            <HeaderBottomTwo />
            <Breadcrumb breadcrumbBg={"https://img.freepik.com/free-photo/young-wheat-green-field_1268-29146.jpg"} heading="Blog" description="We are available for 24/7 for Garden & Landscaping Services" currentPage="Blog List" />
            <BlogStyleFour />
            {/* <Subscribe /> */}
            {/* <FooterTwo /> */}
            <FooterOne/>
        </>
    )
}

export default BlogList;