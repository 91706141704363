import axios from 'axios';
import React, { useState } from 'react'
import { FaSearch } from "react-icons/fa";



const Inputs = ({ setQuary, }) => {

    const [city, setCity] = useState("")
    const [suggestion, setsuggestion] = useState(null)
    const handleSearchclick = (data = null) => {
        if (data) {
            // setCity(data)
            setsuggestion(null)
            setQuary({ q: data })

        }
        else if (city != "" && data == null) {
            setQuary({ q: city })
        }
    }
    const handleChange = async (e) => {
        setCity(e.target.value)
        const resData = await axios.get(`https://api.geoapify.com/v1/geocode/autocomplete?text=${e.target.value}&format=json&apiKey=10ac9cc8061543119b51e155d5d3f3ba`)
        setsuggestion(resData?.data?.results)
    }
    return (
        <div className='input-div'>
            <div>
                <input type="text"
                    onChange={(e) => handleChange(e)}
                    placeholder='Search for cities ...'
                />{
                    suggestion && city !== "" && suggestion?.length ?
                        <div
                            className='remove-scroll mt-2'
                            style={{
                                background: "#0000008c",
                                borderRadius: "10px",
                                color: "#ffffff",
                                textAlign: "center",
                                cursor: "pointer",
                                maxHeight: "150px",
                                overflow: "scroll",
                                position: "absolute",
                                width: "50%",
                                fontSize: "15px",
                                overflowX: "hidden",
                                padding: "15px"
                            }}>
                            <ul>
                                {
                                    suggestion?.map((data, index) => (
                                        <>
                                            <li onClick={() => handleSearchclick((data?.address_line1).split(" ")?.[0])} key={index}>
                                                <span>{data?.formatted}</span>
                                            </li><br />
                                        </>
                                    ))
                                }

                            </ul>
                        </div>
                        :
                        <></>
                }

            </div>
            <FaSearch className='inp-icon cw' style={{ fontSize: "1.2rem", cursor: "pointer", marginTop: "-1px", marginLeft: "10px" }} onClick={() => handleSearchclick()} />
            {/* <i className='inp-icon' style={{ fontSize: "1.3rem" }} class="fa fa-map-marker" aria-hidden="true"></i> */}

            <div style={{ display: "flex", alignItems: "center", paddingLeft: "19px" }}>
                <div className='cw'>
                    °C
                </div>
                <span className='cw' style={{ paddingLeft: "5px" }}>|</span>
                <div className='cw' style={{ paddingLeft: "4px" }}>
                    °F
                </div>
            </div>
        </div>
    )
}

export default Inputs