import React from 'react';

const ContactMap = () => {
    return (
        <>
            <div data-aos="zoom-in-up" className="map-area">
                <iframe 
                    title="Address"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=mahindra%20ashvitha,hydrabad+(Farmer%20Connect)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                </iframe>
            </div>
        </>
    )
}

export default ContactMap;