import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import AboutBlockImg1 from '../../assets/images/hero/1.png';

const AboutVideoBlock = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            {/* <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            /> */}
            <div className="about-block pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div data-aos="slide-right" className="col-xl-6 col-lg-6 col-md-6">
                            <h2>About Farmer Connect</h2>
                            <span>
                                Welcome to Farmer Connect! As a team with deep roots in the farming community, we understand the unique challenges that farmers face daily. Our journey began with a simple idea: to make the lives of farmers easier by connecting them to the resources they need.
                                <br /><br/>
                                Having grown up on a farm, we've witnessed firsthand the hard work and dedication required to produce high-quality products. We've also seen the struggles farmers face in finding reliable buyers, accessing affordable labor, and securing efficient transportation. These challenges can make farming a tough, uphill battle.                        
                                <br /><br />
                                That's why we created Farmer Connect – a platform designed to bridge the gap between farmers and the market. Our mission is to provide farmers with the tools and connections they need to thrive. Whether you are looking to sell your produce, find workers, or arrange transportation, Farmer Connect is here to help.
                                        </span><br />
                            {/* <Link to="/" className="l-btn mt-20">appointment</Link> */}
                        </div>
                        <div data-aos="slide-left" className="col-xl-6 col-lg-6 col-md-6">
                            <div className="hero-popup-content">
                                <img height={400} src="https://r2.erweima.ai/imgcompressed/compressed_988738b1ba0090b7466e1ecf64067df6.webp" alt="" />
                                <div className="hero-popup-video">
                                    {/* <button 
                                        className="popup-video pulse-effect"
                                        onClick={() => setOpen(true)}>
                                            <FaPlay />
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutVideoBlock;