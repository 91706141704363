import React from 'react';
import {Link} from 'react-router-dom';
import HomeOneAboutImg1 from '../../assets/images/about/1.jpg';
import HomeOneAboutImg2 from '../../assets/images/about/2.jpg';

const HomeOneAbout = () => {
    return (
        <div className="about-area white-bg pt-50">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-xl-8 col-lg-6 col-md-12">
                        <div className="about-text-wrapper mb-30">
                            <div className="section-title mr-30 mb-40">
                                {/* <span>Our Services</span> */}
                                {/* <h2>We Are Right Landscape And Gardening Experts Since 2000</h2> */}
                                <h2> What is Farmer Connect ?</h2>
                                <span style={{color:"black",fontSize:"15px"}}> Discover the essence of Farmer Connect, where farmers and customers converge in a digital marketplace, connecting growers directly with consumers. Experience a platform dedicated to promoting transparency, supporting local agriculture, and fostering a community-driven approach to food sourcing. Explore fresh produce, artisanal goods, and sustainable practices, as we bridge the gap between fields and kitchens. Join us in celebrating the richness of farm-to-table traditions, empowering farmers, and delighting food enthusiasts with quality, locally-sourced offerings. Experience the future of food commerce with Farmer Connect.</span>
                                <span style={{color:"black",fontSize:"15px"}}>At Farmer Connect, we're committed to revolutionizing the way people connect with their food. Through our platform, farmers gain direct access to consumers, fostering transparency and trust in the food supply chain. With a focus on sustainability and community, we're reshaping the landscape of agriculture, one transaction at a time. Join us in embracing the future of food commerce and supporting a more resilient, locally-driven food system.</span>
                            </div>
                            {/* <Link href="/" className="l-btn">Appointment</Link> */}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="about-wrapper-style-1 mb-30">
                            <img style={{height:"50%" ,width:"100%",borderRadius:"20px"}} src="https://media.istockphoto.com/id/916530228/photo/farmer-spraying-green-wheat-field.jpg?s=612x612&w=0&k=20&c=GBskI6041-mpV6LM-S-_csWnYeklsvNF9zAGMW1kzSM="  alt="About Us" />
                            {/* <img style={{height:"100%" ,width:"80%"}} className="about-secondary" src="https://media.istockphoto.com/id/916530228/photo/farmer-spraying-green-wheat-field.jpg?s=612x612&w=0&k=20&c=GBskI6041-mpV6LM-S-_csWnYeklsvNF9zAGMW1kzSM=" alt="About Us" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeOneAbout;