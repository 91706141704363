import React from 'react';
import { Link } from 'react-router-dom';
import LogoImg from "../../assets/images/logo.png";
import LogoImg1 from "../../assets/images/FMClogo1 (1).png";

const Logo = () => {
    return (
        <div className="logo">
            <Link to="/">
                <img style={{ height: "59px", width: "200px" }} src={LogoImg1} alt="Logo" />
            </Link>
        </div>
    )
}
export default Logo;